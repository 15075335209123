import {
  identityExperience,
  globalExperience,
  secureExperience,
} from "../constant/svgs";

const ExperienceData = [
  {
    image: identityExperience,
    alt: "identityExperience",
    title: "Single Identity Framework",
    description:
      "Simplify your online presence with our unified digital identity framework. Say goodbye to the complexities of managing multiple identities across various platforms.",
  },
  {
    image: globalExperience,
    alt: "globalExperience",
    title: "Global Reach",
    description:
      "D-iD is available wherever you are, giving you access to your digital identity. It transcends geographical boundaries to cater to the diverse needs of users across the globe.",
  },
  {
    image: secureExperience,
    alt: "secureExperience",
    title: "Secure Authentication",
    description:
      "We implement advanced protocols and robust encryption to protect your information, offering a shield against unauthorized access and guaranteeing a secure online experience.",
  },
];

export default ExperienceData;

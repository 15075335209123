import React from "react";
import ExperienceBoxData from "./ExperienceBoxData";

const ExperienceSection = () => {
  return (
    <>
      <div className="row g-0 experienceBox">
        <div className="col-12 d-flex justify-content-center">
          <div className="w-100 experienceBox1">
            <h4 className="experienceh4 text-black text-uppercase">
              Experience the Future Today with D-iDconnect
            </h4>
            <p className="experiencepara pt-2">
              Take control of your digital identity and embrace a new era of
              security, privacy, and convenience with D-iDconnect. Overall,
              choosing D-iDconnect means choosing security, privacy,
              convenience, and control over your digital identity. Experience
              the difference for yourself and take control of your online
              presence with D-iDconnect.
            </p>
            <ExperienceBoxData />
          </div>
        </div>
      </div>
    </>
  );
};

export default ExperienceSection;

const FeaturesData = [
  {
    video: require("../assets/video/FeatureSection1.mp4"),
    heading: "Facial Recognition",
    description:
      "Replace traditional methods like PINs and passwords with secure authentication using facial recognition technology. Simply scan your face to access your accounts securely.",
  },
  {
    video: require("../assets/video/FeatureSection2.mp4"),
    heading: "Voice Authentication",
    description:
      "Capture your unique vocal characteristics to create a voiceprint for authentication. Your voice becomes your secure access to digital services and devices.",
  },
  {
    video: require("../assets/video/FeatureSection3.mp4"),
    heading: "Signature Recognition",
    description:
      "Detect and recognize your physical signature for an additional layer of authentication. Your signature adds extra security to your digital identity, preventing unauthorized access.",
  },
  {
    video: require("../assets/video/FeatureSection4.mp4"),
    heading: "Identity Verification",
    description:
      "Use D-iDconnect for identity verification in various scenarios, including online transactions and account registrations. Prevent identity theft and fraud with effective identity verification methods.",
  },
];

export default FeaturesData;

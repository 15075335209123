import React from "react";
import HeroSection from "./homesubcomponent/HeroSection";
import ExperienceSection from "./homesubcomponent/ExperienceSection";
import EmpowerSection from "./homesubcomponent/EmpowerSection";
import FeaturesSection from "./homesubcomponent/FeaturesSection";

const HomePage = () => {
  return (
    <>
      <div className="container-fluid p-0 m-0">
        <div className="row g-0 ms-0 ms-lg-4 me-0 me-lg-4 ps-3 pe-3">
          <div className="col-12">
            {/* Section 1 */}
            <HeroSection />
            {/* Section 2 */}
            <ExperienceSection />
            {/* Section 3 */}
            <EmpowerSection />
            {/* Section 4 */}
            <FeaturesSection />
          </div>
        </div>
      </div>
    </>
  );
};

export default HomePage;

import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { BrowserRouter } from "react-router-dom";

import Routes from "./Router.js";
import Navbar from "./components/navbar/Navbar.jsx";
import Footer from "./components/footer/Footer.jsx";
import "./index.css";

const App = () => {
  return (
    <>
      <BrowserRouter>
        <Navbar />
        <Routes />
        {/*    <Footer /> */}
      </BrowserRouter>
    </>
  );
};

export default App;

import { useRef, useEffect } from "react";
import * as images from "../../../constant/images";
import { Download } from "lucide-react";
import EmpowerSectionVideo from "../../../assets/video/EmpowerSectionVideo.mp4";

const EmpowerSection = () => {
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.play().catch((error) => {
        console.error("Error playing the video:", error);
      });
    }
  }, []);
  return (
    <>
      <div className="row g-0 empower_back">
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 d-flex align-items-center">
          <div>
            <div className="ms-0 ms-lg-4 ps-3 ps-lg-3 pt-4 pt-lg-0">
              <h4 className="heroSectionh3">D-iDconnect App</h4>
              <h3 className="heroSectionh1 text-uppercase">
                Empower Your Identity
                <br className="d-none d-xl-block" /> with Advanced Security
              </h3>
              <p className="heroSectionParagraph pt-4">
                Experience 100% security for your digital life with D-iDconnect.
                Our
                <br className="d-none d-xl-block" /> platform connects you to
                the world through a highly secure environment,
                <br className="d-none d-xl-block" /> ensuring your personal
                information is protected in the realms of Web3 and
                <br className="d-none d-xl-block" /> blockchain. Enjoy peace of
                mind as you navigate your digital interactions
                <br className="d-none d-xl-block" /> with the highest level of
                security.
              </p>
              <button className="main_Btn rounded-2 mt-1 d-flex align-items-center gap-2">
                <Download size={20} />
                Download Now
              </button>
            </div>
          </div>
        </div>
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
          <img src={images.EmpowerMobile} alt="EmpowerMobile" width="100%" className="empower_img"/>
        </div>
      </div>
      <div>{/* <EmpowerSlider/> */}</div>
      <div className="row g-0 bg-black">
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 d-flex align-items-center">
          <div>
            <div className="ms-0 ms-lg-4 ps-3 ps-lg-3 pt-4 pt-lg-0">
              <h4 className="heroSectionh3">Global Impact</h4>
              <h3 className="heroSectionh1 text-uppercase">
                Unlock A Seamless Future
                <br className="d-none d-xl-block" /> With Us
              </h3>
              <p className="heroSectionParagraph pt-4">
                D-ID is a digital identity platform that prioritizes user
                privacy, security, and <br className="d-none d-xl-block" />
                control. Revolutionizing the way we manage our online
                identities, D-ID <br className="d-none d-xl-block" /> empowers
                users with advanced privacy features, decentralized control, and
                <br className="d-none d-xl-block" />
                seamless interoperability, ensuring a secure experience.
              </p>
              <button className="main_Btn rounded-2 mt-1 d-flex align-items-center gap-2">
                Sign Up
              </button>
            </div>
          </div>
        </div>
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 d-flex align-items-center">
          <div className="video_div">
            <video
              className="video-opacity"
              autoPlay
              muted
              loop
              ref={videoRef}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            >
              <source src={EmpowerSectionVideo} type="video/mp4" />
            </video>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmpowerSection;

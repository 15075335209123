import React from "react";
import * as images from "../../../constant/images";
import SingleFeature from "./SingleFeature";

const FeaturesSection = () => {
  return (
    <>
      <div className="featureBox pb-4 pt-0 pt-lg-5">
        <div className="row d-flex justify-content-center g-0 pt-0 pt-lg-5">
          <div className="col-xl-9 col-lg-9 col-11 d-flex justify-content-center pt-4">
            <div>
              <h2 className="featureh2 text-black text-center">
                D-iDconnect Mobile Application Features
              </h2>
              <p className="featurepara text-black mt-3">
                These features can further enhance the functionality, security,
                and user experience of the D-iDconnect
                <br className="d-none d-xl-block" /> app, making it even more
                indispensable for users seeking a secure and personalized
                digital identity <br className="d-none d-xl-block" /> management
                solution.
              </p>
            </div>
          </div>
        </div>
        <div className="row d-flex justify-content-center pb-3 pb-lg-5 mt-1 mt-lg-4">
          <div className="col-lg-9 mt-4">
            <img
              src={images.MultiMobile}
              alt="MultiMobile"
              className="img-fluid"
            />
          </div>
        </div>
      </div>
      <div className="featureBox2" style={{ paddingTop: "7%" }}>
        <SingleFeature />
      </div>
    </>
  );
};

export default FeaturesSection;

import React, { useRef, useEffect } from "react";
import HomePageVideo from "../../../assets/video/HomePage.mp4";
const HeroSection = () => {
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.play().catch((error) => {
        console.error("Error playing the video:", error);
      });
    }
  }, []);
  return (
    <>
        <div className="row g-0">
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 pb-3 pb-lg-0 bg-black d-flex align-items-center">
            <div className="ms-0 ms-lg-4 ps-3 ps-lg-3 pt-4 pt-lg-0">
              <h4 className="heroSectionh3">D-iDconnect App</h4>
              <h3 className="heroSectionh1 text-uppercase">
                Get your secure digital presence
                <br className="d-none d-xl-block" /> in digital world with
                Blockchain,
                <br className="d-none d-xl-block" /> web3 and AI completely
                under
                <br className="d-none d-xl-block" /> your control.
              </h3>
              <p className="heroSectionParagraph pt-4">
                With D-iD, you're not just a user, you're part of a movement
                toward <br className="d-none d-xl-block" /> a safer, more secure
                online world.
              </p>
              <button className="main_Btn rounded-2 mt-1">Get Started</button>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <div className="video_div">
              <video
                className="video-opacity"
                autoPlay
                muted
                loop
                ref={videoRef}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              >
                <source src={HomePageVideo} type="video/mp4" />
              </video>
            </div>
          </div>
        </div>
    </>
  );
};

export default HeroSection;

import React from "react";
import ExperienceData from "../../../data/ExperienceData";

const ExperienceBoxData = () => {
  return (
    <>
      <div className="row mt-0 mt-lg-4 pt-0 pt-lg-3">
        {ExperienceData.map((experience, index) => (
          <div
            key={index}
            className="col-xl-4 col-lg-4 col-md-6 col-sm-12 pe-3 pe-md-0 pe-xl-5 mt-3 mt-lg-0"
          >
            <div className="ps-4 pe-3 pb-1 pt-4 rounded-4 experienceBox2">
              <div className="pt-3">{experience.image}</div>
              <h6 className="experienceh5 mt-2 pt-4 text-black">
                {experience.title}
              </h6>
              <p className="text-black experiencepara1 fw-bold pt-2">
                {experience.description}
              </p>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default ExperienceBoxData;

import React from "react";
import { Link } from "react-router-dom";
import * as images from "../../constant/images";
import { AlignJustify } from "lucide-react";
import { searchHomeIcon, loginHomeIcon } from "../../constant/svgs";
const Navbar = () => {
  return (
    <>
      <div className="container-fluid p-0 m-0">
        <div className="row ms-0 ms-lg-4 me-0 me-lg-4">
          <div className="col-12 pt-2 pb-2">
            <nav className="navbar navbar-expand-lg pt-3 pb-3">
              <div className="container-fluid">
                <Link to="/" className="ps-0 ps-lg-3">
                  <img src={images.Logo} alt="logo" width={130} />
                </Link>
                <button
                  className="navbar-toggler border-0 bg-transparent text-decoration-none"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <AlignJustify size={25} color="black" />
                </button>
                <div
                  className="collapse navbar-collapse"
                  id="navbarSupportedContent"
                >
                  <ul className="navbar-nav navbar_home m-auto mb-2 mb-lg-0">
                    <li className="nav-item ps-2 ps-lg-0 pe-2 mt-4 mt-lg-0">
                      <Link
                        className="nav-link fs-6 active"
                        aria-current="page"
                        to="/"
                      >
                        Home
                      </Link>
                    </li>
                    <li className="nav-item ps-2 pe-2">
                      <Link
                        className="nav-link fs-6"
                        aria-current="page"
                        to="/"
                      >
                        Features
                      </Link>
                    </li>
                    <li className="nav-item ps-2 pe-2">
                      <Link
                        className="nav-link fs-6"
                        aria-current="page"
                        to="/"
                      >
                        Blog
                      </Link>
                    </li>
                    <li className="nav-item ps-2 pe-2">
                      <Link
                        className="nav-link fs-6"
                        aria-current="page"
                        to="/"
                      >
                        Contact
                      </Link>
                    </li>
                    <li className="nav-item ps-2 pe-2">
                      <Link
                        className="nav-link fs-6"
                        aria-current="page"
                        to="/"
                      >
                        About
                      </Link>
                    </li>
                  </ul>
                  <div className="loginSearchBtn d-flex align-items-center gap-3 ps-2 ps-lg-0 pe-0 pe-lg-4">
                    {searchHomeIcon}
                    {loginHomeIcon}
                  </div>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;

import React, { useEffect, useRef } from "react";
import FeaturesData from "../../../data/FeaturesData"; // Adjust the path as necessary

const SingleFeature = () => {
  return (
    <>
      {FeaturesData.map((feature, index) => {
        const isReverse = index % 2 !== 0;
        return (
          <div
            className={`row g-5 ms-2 me-2 ${
              isReverse ? "flex-row-reverse" : ""
            }`}
            key={index}
          >
            <div className="col-xl-7 col-lg-7 col-md-6 col-12">
              <div className="pb-0 pb-lg-5">
                <VideoComponent src={feature.video} />
              </div>
            </div>
            <div className="col-xl-5 col-lg-5 col-md-6 col-12 d-flex align-items-center">
              <div className="">
                <h3 className="text-white featureh3_1">{feature.heading}</h3>
                <p className="text-white featurepara_1 mt-3 pt-4 pb-4">
                  {feature.description}
                </p>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

const VideoComponent = ({ src }) => {
  const videoRef = useRef(null);

  useEffect(() => {
    const videoElement = videoRef.current;

    if (videoElement) {
      videoElement.muted = true;
      videoElement.loop = true;
      videoElement.playsInline = true;

      videoElement.addEventListener("loadeddata", () => {
        videoElement.play().catch((error) => {
          console.error("Error playing the video:", error);
        });
      });
    }

    return () => {
      if (videoElement) {
        videoElement.removeEventListener("loadeddata", () => {
          videoElement.play().catch((error) => {
            console.error("Error playing the video:", error);
          });
        });
      }
    };
  }, []);

  return (
    <video
      className="video-opacity"
      id="Feature_video1"
      autoPlay
      muted
      loop
      playsInline
      ref={videoRef}
    >
      <source src={src} type="video/mp4" />
    </video>
  );
};

export default SingleFeature;
